import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom/client';
import './i18n/i18next';
import { Suspense, lazy } from 'react';
import useApplyGlobalStyles from './hooks/useApplayStyle';
import { initGA } from './analytics';
import { sentryInit } from './helpers/sentryInit';
const MobileScreen = lazy(() => import('@/pages/MobileScreen/MobileScreen'));
const App = lazy(() => import('./App'));
import { addGoogleTagManager, NoScript } from './helpers/googleTagManagerInit';
import { hotjarInit } from './helpers/hotjarInit';
import './helpers/pwaRefetch';
import { ErrorBoundary } from './helpers/ErrorBoundry';

if (import.meta.env.VITE_REACT_APP_ENV === 'production') {
  initGA();
  addGoogleTagManager();
  hotjarInit();
}

sentryInit();
const ContainerApp: React.FC = () => {
  const { isMobile } = useApplyGlobalStyles();
  return (
    <>
      <Suspense fallback={null}>
        {isMobile ? (
          <ErrorBoundary>
            <MobileScreen />
          </ErrorBoundary>
        ) : (
          <App />
        )}
      </Suspense>
      <NoScript />
    </>
  );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<ContainerApp />);
