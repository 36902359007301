import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom/client';
import './i18n/i18next';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Suspense, lazy } from 'react';
import { EXT } from './env';
import { registerSW } from 'virtual:pwa-register';
import useApplyGlobalStyles from './hooks/useApplayStyle';
import { initGA } from './analytics';

const MobileScreen = lazy(() => import('@/pages/MobileScreen/MobileScreen'));
const App = lazy(() => import('./App'));

const intervalMS = 15 * 60 * 1000;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateSW = registerSW({
  onRegisteredSW(swUrl: string, registration: ServiceWorkerRegistration | undefined) {
    if (!registration) return;

    setInterval(async () => {
      try {
        if (!registration.installing && navigator) {
          if ('connection' in navigator && !navigator.onLine) return;
          if (!navigator.serviceWorker.controller) return;

          const response = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              'cache-control': 'no-cache',
            },
          });

          if (response.status === 200) {
            await registration.update();
          } else {
            console.warn('Service worker update fetch failed:', response);
          }
        }
      } catch (error) {
        console.error('Error during service worker update check:', error);
      }
    }, intervalMS);
  },
});

if (import.meta.env.VITE_REACT_APP_ENV === 'production') {
  initGA();
}

if (import.meta.env.VITE_REACT_APP_ENV !== 'development') {
  Sentry.init({
    release: 'sobrus-pharma@' + '3.2.0',
    dsn: 'https://fb516dbf1d9a454cb85e2398e7e5b5d1@o4505092585816064.ingest.sentry.io/4505245215096832',
    environment: import.meta.env.VITE_REACT_APP_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', `app.pharma.sobrus.${EXT}`, /^\//],
      }),
    ],
    beforeSend(event) {
      if (event.request?.url?.includes('/api/')) {
        return null;
      }
      return event;
    },
    sendDefaultPii: true,
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      /^Failed to fetch dynamically imported module/,
      /^Unable to preload CSS/,
      /^Request aborted/,
      /^Network Error/,
      /^chrome-extension:/,
      /^error loading dynamically imported/,
      /^Importing a module script failed/,
    ],
  });
}

const ContainerApp: React.FC = () => {
  const { isMobile } = useApplyGlobalStyles();
  return <Suspense fallback={null}>{isMobile ? <MobileScreen /> : <App />}</Suspense>;
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<ContainerApp />);
