const intervalMS = 15 * 60 * 1000;
import { registerSW } from 'virtual:pwa-register';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const updateSW = registerSW({
  onRegisteredSW(swUrl: string, registration: ServiceWorkerRegistration | undefined) {
    if (!registration) return;

    setInterval(async () => {
      try {
        if (!registration.installing && navigator) {
          if ('connection' in navigator && !navigator.onLine) return;
          if (!navigator.serviceWorker.controller) return;

          const response = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              'cache-control': 'no-cache',
            },
          });

          if (response.status === 200) {
            await registration.update();
          } else {
            console.warn('Service worker update fetch failed:', response);
          }
        }
      } catch (error) {
        console.error('Error during service worker update check:', error);
      }
    }, intervalMS);
  },
});
