import ReactGA from 'react-ga4';
import { VITE_GA_MEASUREMENT_ID } from './env';

export const initGA = () => {
  const measurementId = VITE_GA_MEASUREMENT_ID;
  ReactGA.initialize(measurementId);
};

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};
