import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export const sentryInit = () =>
  Sentry.init({
    release: 'sobrus-pharma@' + '3.2.2',
    environment: import.meta.env.VITE_REACT_APP_ENV,
    dsn: 'https://fb516dbf1d9a454cb85e2398e7e5b5d1@o4505092585816064.ingest.sentry.io/4505245215096832',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    enabled: import.meta.env.VITE_REACT_APP_ENV !== 'development',
    autoSessionTracking: true,
    tracePropagationTargets: ['localhost', /^https:\/\/api\.account\.sbrus\.${EXT}/],
    beforeSend(event) {
      if (event.request?.url?.includes('/api/')) {
        return null;
      }
      if (event.level === 'warning') {
        return null; // Ne pas envoyer les avertissements
      }
      return event;
    },
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      /^Failed to fetch dynamically imported module/,
      /^Unable to preload CSS/,
      /^Request aborted/,
      /^Network Error/,
      /^chrome-extension:/,
      /^error loading dynamically imported/,
      /^Importing a module script failed/,
    ],
  });
